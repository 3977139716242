import {
  type CSSProperties,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

function TickerContent(props: {
  speed: number;
  delayMs?: number;
  children: ReactNode;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (!ref.current) return;

    setDuration(Math.floor(ref.current.clientWidth / props.speed));
  }, [props.speed]);

  return (
    <div
      className={`min-w-full flex-none ${duration ? 'animate-ticker' : ''}`}
      style={
        {
          '--tw-ticker-duration': `${duration}s`,
          '--tw-ticker-delay': `${props.delayMs || 0}ms`,
        } as CSSProperties
      }
      ref={ref}
    >
      {props.children}
    </div>
  );
}

export function Ticker(props: {
  children: ReactNode;
  speed?: number;
  delayMs?: number;
  className?: string;
}) {
  const { speed = 30 } = props;
  return (
    <div
      className={`w-full flex items-center overflow-hidden ${props.className}`}
    >
      <TickerContent speed={speed} delayMs={props.delayMs}>
        {props.children}
      </TickerContent>
      <TickerContent speed={speed} delayMs={props.delayMs}>
        {props.children}
      </TickerContent>
    </div>
  );
}
